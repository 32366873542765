import React from "react"
import Main from './Main'
import Login from './Login';

export default function Combiner(){


    let loginData
    let localLogin = localStorage.getItem("Login")

    if (localLogin === 'true'){
      loginData = true
    }
    else{
      loginData = false
    }

        // Get the stored time
/*    let storedTime = new Date(localStorage.getItem("Login"));

    // Get the current time
    let currentTime = new Date();

    // Calculate the difference in minutes
    let differenceInMinutes = (currentTime - storedTime) / (1000 * 60);

    if (differenceInMinutes >= 5 && !localStorage.getItem("reloaded")) {
      console.log('test')
        loginData = true
    }
    else{
      console.log('storedTime')
        loginData = false
    }
    */




    const [login, setLogin] = React.useState(loginData)
    
    function changeLogin(){
        setLogin(!login)
      }

      return(

        <>
        {
          login? <Main/>: <Login login={login} changeLogin={changeLogin} />
        }
        </>
      )
}