import React from "react"

const Body = (props) => {
    const [info, setInfo] = React.useState(false)
  
    const toggleInfo = () => {
      setInfo(prev => !prev)

    }

/*
        {
            "kurs": "TDIU08 Problemlösning och programmering",
            "poäng": "6",
            "år": "2020-01-13",
            "examinator": "Torbjörn Jonsson",
            "finished": true,
            "moment": 
                {
                    "lab": "G",
                    "tenta": "4"
                }
            
        },
*/
 
return(
        <div>
            <div className="main-content" onClick={toggleInfo}>
                <div className="inner-content">
                    <p className="course-name">{props.kurs}</p>
                    <p className="course-point">{props.poäng} Hp</p>
                    {                
                        props.finished? <div className="course-status green">Avklarad</div>:<div className="course-status blue">Registrerad</div>
                    }                
                </div>
                    {
                    info && <div>
                        <div className={`drop-down ${props.finished? 'ddgreen':'ddgray'}`}>
                            <h3>Slutbetyg:  {props.moment.tenta}</h3>
                            <div className="spread">
                                <h3>Examinationsdatum: </h3>
                                <p>{props.år}</p>
                            </div>
                            <div className="spraed">
                                <h3>Examinator: </h3>
                                <p>{props.examinator}</p>
                            </div>
                            <div className="spread">
                                <h3>Omfattning: </h3>
                                <p>{props.poäng} Hp</p>
                            </div>
                        </div>
                    </div>
                    }
            </div>
            <hr />
        </div>
    )
}

export default Body