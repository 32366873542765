import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Combiner from './components/Combiner';
import Verify from './components/Verify';
import Register from './components/Register';

function App() {


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Combiner/>} />
          <Route path="Verify" element={<Verify />} />
          <Route path='Register' element={<Register />} />
        </Routes>
      </BrowserRouter>
    </>

  )
}

export default App