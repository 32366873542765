import React from "react"

const Footer = () => {
    return(
        <div className="footer">
            <div className="container">
                <div className="footer_contact">
                    <p className="white">Contact the university</p>
                    <p className="white">013-28 10 00</p>
                    <p className="white">infocenter@liu.se</p>
                    <p className="white">Linköping University</p>
                </div>
                <div className="links">
                    <p className="white">External links</p>
                    <p className="white">Accessibility statement</p>
                    <p className="white">Acceptable Use Policy</p>
                    <p className="white">Cookies</p>
                </div>
            </div>
        </div>
        
    )
}

export default Footer