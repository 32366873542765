import React from "react"
import liuLogo from '../assets/liu-logo.svg'

const name = (localStorage.getItem('Name'))
const pnum = (localStorage.getItem('Pnum'))


const Header = () => {
    return(
        <div className="header">
            <img src={liuLogo} />
            <div className="person">
                <h2 className="white">{name}</h2>
                <p className="white">{pnum}</p>
            </div>
        </div>
    )
    
}

export default Header