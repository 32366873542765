import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import { Link } from "react-router-dom"
import Body from "./Body"

export default function Main(){

    const data = JSON.parse(localStorage.getItem('MyData'))
    console.log(data)

    const body = data?.map(item =>{{
      return <Body key={item.kurs} {...item}/>
        }
    })

    return(
        <div className="app">
            <Header />
            <div className="main-container">
                <h1 className="main-h1">Min Utbildning</h1>


                {/*
                <h2>Filtrera</h2>
                <div className='button-container'>
                <button className='filter-button'>Alla</button>
                <button className='filter-button'>Avklarade</button>
                <button className='filter-button'>Inte Avklarade</button>
                </div>
            */}
                <h3 className="education-title">Högskoleingenjör Datateknik</h3>
                <Link className="button-7" to={'/Register'}>Exam Register</Link>
                <section className='body-section'>
                    {body}
                </section>
            </div>
            <Footer />
      </div>
    )
}
