import React from "react"
import larosate from '../assets/larosate.svg'
import ediu from '../assets/eduid.svg'
import antaging from '../assets/antagning.svg'

export default function Login(props){

   
    const openAntag = ()=>{
        const url = "https://www.antagning.se/se/login"
        window.open(url, "_blank")
    }

    return(
        <div className="login-page">
            <h1 className="black">Välkommen till Ladok för studenter</h1>
            <h2 className="grey">Welcome to Ladok for students</h2>
            <div className="login-box">
                <div className="text-box">
                    <h3 className="black">Inloggning</h3>
                    <h3 className="grey">Login</h3>
                </div>
                <button onClick={openAntag} className="login-button">
                <div className="button-text-container">
                    <img className="button-img" src={larosate}/>
                    <div className="button-text-horizontal">
                        <span className="button-text">
                            Inloggning via ditt lärosäte
                        </span>
                        <br></br>
                        <span className="button-text">
                            Access through your institution
                        </span>
                    </div>
                </div>
                </button>
                <div className="text-box">
                    <h3 className="black">Inloggning till lärosäten som inte tillhandahåller studentkonton</h3>
                    <h3 className="grey">Log in to universities that don’t provide student accounts</h3>
                </div>
                <button className="alt-login-button">
                <div className="button-text-container">
                    <img className="button-img-alt-two" src={ediu}/>
                    <span className="button-text">
                        eduID
                    </span>
                </div>
                </button>
                <button className="alt-login-button">
                    <div className="button-text-container">
                        <img className="button-img-alt" src={antaging}/>
                        <span className="button-text">
                            Antagning
                        </span>
                    </div>
                </button>
                <div className="text-box">
                    <h3 className="black">Jag är inte längre en aktiv student och saknar studentkonto</h3>
                    <h3 className="grey">I am no longer an active student and do not have a student account</h3>
                </div>
            </div>
        </div>
    )
}