import React from "react"
import Header from "./Header"
import Footer from "./Footer"

export default function Register(){

    return(
        <div className="app">
            <Header />
            <div className="tenta">
                <div className="tenta-container">
                    <div className="tenta-mold">
                        <div className="tenta-name">TDTS04</div>
                        <div className="tenta-name">Datornät och distribuerade system</div>
                        <div className="tenta-date">24-06-05</div>
                        <div className="tenta-time">08:00 till 12:00</div>
                        <button className="green reg-button">Registrerad</button>
                    </div>
                    <div className="tenta-mold">
                        <div className="tenta-name">TAIU10</div>
                        <div className="tenta-name">Analys i en variabel</div>
                        <div className="tenta-date">24-06-05</div>
                        <div className="tenta-time">13:00 till 17:00</div>
                        <button className="green reg-button">Registrerad</button>
                    </div>
                    <div className="tenta-mold">
                        <div className="tenta-name">TDTS04</div>
                        <div className="tenta-name">Datornät och distribuerade system</div>
                        <div className="tenta-date">25-01-13</div>
                        <div className="tenta-time">08:00 till 12:00</div>
                        <button className="reg-button"></button>
                    </div>
                    <div className="tenta-mold">
                        <div className="tenta-name">TAIU10</div>
                        <div className="tenta-name">Analys i en variabel</div>
                        <div className="tenta-date">24-10-28</div>
                        <div className="tenta-time">08:00 till 12:00</div>
                        <button className="reg-button"></button>
                    </div>
                </div>
            </div>
            <Footer />
      </div>
    )
}
