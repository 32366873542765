import React from 'react'
import ladokLogo from '../assets/ladok-logo.svg'

export default function Verify(){

    const perInput = React.useRef()
    const kodInput = React.useRef()

    function verifyInput(event){
        if (localStorage.getItem('Pnum') === perInput.current.value.toString() && localStorage.getItem('kod') === kodInput.current.value.toString()){
            const url = localStorage.getItem('document')
            window.open(url, "_blank")
        }
        event.preventDefault()   
    }   


    return(
        <div className='verify-container'>
            <div className='verify-header'>
                <img className='verify-img' src={ladokLogo} />
            </div>
            <div className='maintnace'>Tillfälligt underhåll pågår</div>
            <main className='verify-main'>
                <h1>Kontrollera intyg</h1>
                <p> Studiedokumentationssystemet Ladok används av 40 svenska universitet och högskolor.
                    På denna sida kan dessa kontrolleras. Använd koden längst ner på intyget för att visa originalet på skärmen. </p>
                <form onSubmit={verifyInput}>
                    <label htmlFor="per">Personnummer</label><br/>
                    <input type='text' ref={perInput} id='per' name='per' placeholder='ÅÅÅÅMMDD-NNNN'></input><br/>
                    <label htmlFor="kod">Kontrollkod</label><br/>
                    <input type='text' ref={kodInput} id='kod' name='kod' placeholder='Kontrollkod'></input>
                    <button onClick={verifyInput}>Verifiera</button>
                </form>
            </main>
            <footer>
                <div className='verify-footer'>
                    <div className="verify-content-footer">
                        <h1 className='verify-h1'>Kontakt</h1>
                        <h3 className='verify-h3'>Ladokkonsortiet</h3>
                        <h3 className='verify-h3'>Wallingatan 2, vån 7</h3>
                        <h3 className='verify-h3'>111 60 Stockholm</h3>
                    </div>
                </div>
            </footer>

        </div>
    )
}